import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function Portfolio() {
  return (
    <>
      {/* <h1 className="portfolio">PORTFOLIO</h1> */}
      {/* <div className="logo-container">
        <img
          className="rfm-full-logo"
          src="\images\ReelFocusMedia_FullColorLogo.png"
          alt="RFM logo"
        />
      </div> */}

      <div className="portfolio-intro">
        <h1>Portfolio</h1>
        {/* <p>
          Take a look at our portfolio to get a sense of what you can expect
          from our services.
        </p> */}
      </div>
      <div className="iframe-container">
        <h1>Business Events</h1>
        <div className="business-vids">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/QpgqiwfAU0U?si=pccuSkhR5qP77z1M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/6uT6QWGqVEk?si=fsQ59JvLRsqcrp59"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1VN3hNSzUrM?si=Oj2ObtGfEoTvLadi"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <h1>Weddings</h1>
        <div className="wedding-vids">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LUgaqgRIt1c?si=LBzsrHiSUpDTHFYm&amp;controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/B7s7s_qJXVY?si=NAspDbq2W9ZByZnq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/AcDmPmUoKWo?si=kuvNWV3XCj_wG2SI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <h1>and so much more!</h1>
        <div className="more-vids">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UohA0talvxM?si=FffoPCFOqG2v_3FI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>

          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/zsQYvZmLlEc?si=ZzBnCxRS2kTkRCAI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/L9Klwq-XUrs?si=uQVJCGcjgHEIbv8o"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className="package-questions">
        <p>
          For more information regarding the services we provide, please feel
          free to contact us for any questions!
        </p>
        <br />
        <p>Your vision is our priority!</p>
        <hr></hr>
        <a href="/sign-up" className="contact-us-button">
          CONTACT US
        </a>
        <p>Call or text us: 909-762-2159</p>
      </div>
      <Footer />
    </>
  );
}
