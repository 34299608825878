import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      <video
        src="/videos/pexels-kelly-lacy-9699970 (2160p).mp4"
        autoPlay
        loop
        muted
      />
      {/* <h1>ADVENTURE AWAITS</h1> */}
      <img
        src="/images/ReelFocusMedia_FullColorLogo.png"
        className="full-rfm-logo"
        alt="Full RFM Logo"
      />
      <p>Unleashing the Power of Visual Story Telling</p>
      <div className="hero-btns">
        {/* <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Portfolio
        </Button> */}
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          {/* WATCH TRAILER <i className="far fa-play-circle" /> */}
          Contact Us
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
