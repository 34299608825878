import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Cards from "../Cards";
import Footer from "../Footer";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function Home() {
  return (
    <>
      <HeroSection />
      <div className="homepage-intro">
        <p>
          Welcome to Reel Focus Media! We specialize in creating unforgettable
          memories through videography. With a passion for storytelling, we
          offer a diverse range of services tailored to your unique needs. From
          cinematic weddings to dynamic content, we deliver high-quality videos
          that leave a lasting impression. Explore our portfolio and let us
          bring your vision to life!
        </p>
        <a href="/portfolio" className="homepage-portfolio-btn">
          Portfolio
        </a>
      </div>
      <Footer />
    </>
  );
}

export default Home;
