import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function BusinessEvents() {
  return (
    <>
      <div className="services-container">
        <div className="packages-intro">
          {/* <img src="\images\ReelFocusMedia_White.png" alt="" /> */}
          <h1>Business Events</h1>
          <div className="business-packages-intro">
            <p>
              Have an upcoming business event? Reel Fous Media has you covered
              on any of your videography needs. Whether it be action footage of
              the even or cinematic drone sweeping shots. Check out some of our
              recent business events down below!
            </p>
            <div className="business-disclaimer">
              <p>
                Business Event packages are in development and will be coming
                soon!
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="business-event-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/QpgqiwfAU0U?si=pccuSkhR5qP77z1M"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6uT6QWGqVEk?si=fsQ59JvLRsqcrp59"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/1VN3hNSzUrM?si=Oj2ObtGfEoTvLadi"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="package-questions">
        <p>
          Please reach out to us to discuss any needed alterations or addons for
          any of our available packages.
        </p>
        <br />
        <p>Your vision is our priority!</p>
        <hr></hr>
        <a href="/sign-up" className="contact-us-button">
          CONTACT US
        </a>
        <p>Call or text us: 909-762-2159</p>
      </div>
      <Footer />
    </>
  );
}
