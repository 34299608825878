import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function SignUp() {
  return (
    <>
      <div className="contact-container">
        <div className="auth-modal">
          <img src="/images/ReelFocusMedia_FullColorLogo.png" alt="" />
          <p>Contact us to discuss your upcoming event!</p>
          <div className="call-us">
            <p>Call or Text us:</p>
            <div className="phone-number">
              <p>909-762-2159</p>
            </div>
          </div>

          <div className="connect-us">
            <p>Connect with us on</p>
            <div className="social-icons">
              <a
                href="https://instagram.com/reelfocusmedia_?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <img
                  src="images/instagram-167-svgrepo-com.svg"
                  alt="instagram icon"
                  class="instagram-logo"
                />
              </a>
              <a href="https://www.youtube.com/@ReelFocusMedia" target="_blank">
                <img
                  src="images/youtube-168-svgrepo-com.svg"
                  alt="youtube icon"
                  class="youtube-logo"
                />
              </a>
              <a href="mailto: rfm@reelfocusmedia.com">
                <img
                  src="images/email-1573-svgrepo-com.svg"
                  alt="email icon"
                  class="email-icon"
                />
              </a>
            </div>
          </div>
          <br></br>
        </div>
      </div>
      <Footer />
    </>
  );
}
