import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function Products() {
  return (
    <>
      <div className="about-us-container">
        <div className="logo-avatars">
          <img src="/images/JoeAvatar.png" alt="joey-avatar" />
          <img src="/images/KevinAvatar.png" alt="kevin-avatar" />
        </div>
        <p>
          Welcome to Reel Focus Media! We're more than just videographers; we're
          passionate storytellers dedicated to capturing moments that matter.
          Our dynamic duo specializes in a wide range of videography services
          tailored to meet your unique needs. Whether it's the excitement of
          Business Events, the timeless romance of Weddings, the warmth of
          Family Events, the compelling narratives of Documentary Work, or the
          engaging episodes of Podcasting, we bring creativity, attention to
          detail, and authenticity to every project.
        </p>
        <br />
        <p>
          At Reel Focus Media, we believe that every event has its own story
          waiting to be told. We take pride in seamlessly blending into the
          background, allowing moments to unfold naturally while ensuring that
          every significant detail is beautifully preserved on film. Your vision
          is our mission, and we work closely with you to understand your goals,
          preferences, and expectations.
        </p>
        <br />
        <p>
          We're excited to embark on this creative journey with you. Whether
          it's a business event, wedding, family gathering, special project, or
          podcasting venture, we're here to turn your ideas into unforgettable
          visual experiences. Contact us today, and let's start crafting the
          perfect video for your next project!
        </p>
        <div className="sign-off">
          <br />
          <p>-Joey & Kevin</p>
        </div>
        <div className="final-logo">
          <img src="/images/ReelFocusMedia_FullColorLogo.png" alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
  // return <h1 className="products">ABOUT US</h1>;
}
