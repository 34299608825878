import React from "react";
import "../../App.css";
import Footer from "../Footer";

export default function Weddings() {
  return (
    <>
      <div className="services-container">
        <div className="packages-intro">
          <h1>Wedding Packages</h1>

          <div className="wedding-packages-intro">
            <p>
              Your special day is of the utmost importance. We have curated a
              selection of Wedding Packages to ensure that the memories of your
              special day can be relived for years to come.
            </p>
            <br />
            <div className="wedding-disclaimer">
              <p>
                Wedding packages begin at $1,800. Please contact us for any
                questions of possible alterations to these packages.
              </p>
            </div>
          </div>
        </div>
        <div className="packages-available">
          <div className="package-option">
            <h2>Standard Package</h2>
            {/* <h3>$1,850</h3> */}
            <br></br>
            <hr></hr>
            <ul>
              <li>Half day 6-hour wedding day shoot</li>
              <li>Footage of ceremony and reception only</li>
              <li>2 Cameras</li>
              <li>Song hightlight music video 3-5 min.</li>
            </ul>
            <button className="package-button">BOOK NOW</button>
          </div>

          <div className="package-option">
            <h2>Advanced Package</h2>
            <br></br>
            {/* <h3>$2,750</h3> */}
            <hr></hr>
            <ul>
              <li>Full day 12 hour wedding day shoot</li>
              <li>
                Footage of wedding part getting ready, ceremony, and reception
              </li>
              <li>2 Cameras</li>
              <li>Drone footage $150</li>
              <li>2 song highlight music videos 6-8 min.</li>
            </ul>
            <button className="package-button">BOOK NOW</button>
          </div>
          <div className="package-option">
            <h2>Deluxe Package</h2>
            <br></br>
            {/* <h3>$3,650</h3> */}
            <hr></hr>
            <ul>
              <li>Full day 12 hour wedding day shoot</li>
              <li>
                Footage of wedding part getting ready, ceremony, and reception
              </li>
              <li>3 Cameras</li>
              <li>Drone footage $150</li>
              <li>HD recorded Audio speeches & ceremony</li>
              <li>Multiple song highlight music videos 8-10 min.</li>
            </ul>
            <button className="package-button">BOOK NOW</button>
          </div>
          <div className="package-option">
            <h2>Premium Package</h2>
            <br></br>
            {/* <h3>$5,500+</h3> */}
            <hr></hr>
            <ul>
              <li>
                Half day 6 hour shoot before wedding day at couples favorite
                local location
              </li>
              <li>Full day 12 hour wedding day shoot</li>
              <li>
                Footage of wedding part getting ready, ceremony, and reception
              </li>
              <li>3 Cameras</li>
              <li>Drone footage</li>
              <li>HD recorded Audio speeches & ceremony</li>
              <li>Bride and groom interviews</li>
              <li>Multiple song highlight music videos 13-15 min.</li>
              {/* <li>Customized add ons. TBD on couples needs and wants.</li> */}
            </ul>
            <button className="package-button">BOOK NOW</button>
          </div>
        </div>
      </div>
      <div className="package-questions">
        <p>
          Please reach out to us to discuss any needed alterations or addons for
          any of our available packages.
        </p>
        <br />
        <p>Your vision is our priority!</p>
        <hr></hr>
        <a href="/sign-up" className="contact-us-button">
          CONTACT US
        </a>
        <p>Call or text us: 909-762-2159</p>
      </div>
      <Footer />
    </>
  );
}
